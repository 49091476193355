$purple: #6F27F6; 
 
$Primary-color: $purple; // Originally #e43030
$Secondary-color: #f2f2f2; // Originally #F2F2F2
$SecondaryDK-color: hsl(0, 0%, 90%); // Originally #c5bebe
$Base-color: #ffffff; // Originally #FFFFFF
 
:root {
  --selected-bg-color: #{$Primary-color}; // Your $Primary-color equivalent
  --hover-bg-color: #6F27F6; //   here 8f is the opacity
  --primary-color: #{$Primary-color};
}
 
.grid-bar-selected-bg {
  background-color: var(--primary-color) !important; // Use the CSS variable here
}
 

.basePrimary {
  display: flex;
  height: 10svh;
  background-color: $Primary-color;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.baseSecondary {
  display: flex;
  height: 2vh;
  background-color: $Base-color;
  position: sticky;
  top: 15vh;
  z-index: 1000;
}

.base {
  background-color: $Secondary-color;
  display: flex;
  flex-direction: column;
  padding: 6vh 10vh;
  height: calc(100vh - 27vh); 

  // Adjusted to account for the header height
  overflow-y: auto;
  
  // Enables scrolling for the content area
  width: 100%;
}

.base-button {
  background-color: $Secondary-color;
  position: fixed;
  bottom: 0;
  height: 10vh;
  z-index: 1000;
}

.button-container {
  display: flex;
  place-content: space-evenly;
  width: 100%;
  padding: 10px; 
}

.button-color {
  background-color: $Primary-color !important;
}


.selected-bg {
  background-color: var(--selected-bg-color) !important;
}

.hover-bg {
  background-color: var(--hover-bg-color) !important;
}

// Single select // RadioButton
input[type="radio"]:checked{
  background-color: $Primary-color;
  border-color: $Primary-color;
  accent-color: $Primary-color;
}

// Single select // StarRating // HorizntalRatingScale // Answer button
.base-bg{
  background-color: $SecondaryDK-color !important;  //Unselected color of stars
}

.selected-bg{
  background-color: $Primary-color !important; //Selected color of stars
}

.border-none{
  border: none;
}

.border-color{
  border-color: $Primary-color !important; //Selected color of border
}

//Single // multiDropDown
.css-1p3m7a8-multiValue {
  //background-color: $Primary-color !important; // Background color
  color: $Base-color; // cross color
}
.css-1x9qkw2-singleValue{
  background-color: $Primary-color !important; // Background color
  

}

.css-wsp0cs-MultiValueGeneric {
  color: $Base-color !important; // Selected Text color
}

.dropdown-item:active {
  background-color: $Primary-color !important; // Background color
}


// Handle the width of the Other button
$breakpoints: (
  'mobile': 480px,
  'tablet': 768px,
  'laptop': 1024px,
);

@mixin respond-to($device) {
  @media (max-width: map-get($breakpoints, $device)) {
    @content;
  }
}

.button-width {
  width: 30%;


  @include respond-to('laptop') {
    width: 30% !important;
  }

  @include respond-to('tablet') {
    width: 50% !important;
  }

  @include respond-to('mobile') {
    width: 100% !important;
  }
}

.vertical-slider-container {
  display: flex;
  flex-direction: column;
  height: 100vh; 
}

.MuiSlider-thumb {
  background-color: var(--selected-slider-color, $Primary-color) !important;
}
 
.MuiSlider-track {
  background-color: var(--selected-slider-color,$Primary-color) !important;
  color: var(--selected-slider-color, $Primary-color) !important;
}

.other-text-input {
  background-color: $Secondary-color;
  border-color: $Primary-color;
}

.grid-option {
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.grid-option-container {
  width: 100%;
}

.skip-to-container label {
  font-weight: bold;
  margin-left: 1%;
}
// HorizntalRatingScale
.base-hbg {
  background-color: #e4f3f5;
}
 

.skip-to-container select {
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  background-color: #f9f9f9;
  color: #333333;
  transition: border-color 0.3s ease;
  width: 100%;
}

.skip-to-container select:hover {
  border-color: #00796b;
}
#text-start .error-message {
  color: red;
  font-size: 1.25rem;
  border-left: 4px solid #f44336;
  padding-left: 8px;
  background-color: #ffe6e6;
  border-radius: 4px;
  margin-bottom: 2%;
}

.response-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Spacing between label and input */
  width: 70%;
}

.response-container .other-textarea {
  //margin: 0;
  width: 30%; /* Adjust input width */
}

/* Mobile View */
@media (max-width: 768px) {
  .response-container {
    flex-direction: column; /* Stack label and input vertically */
    align-items: flex-start;
    
  }
  .single-labels{
    flex-direction: row; /* Keep label and input in the same row */
    flex-wrap: nowrap;  /* Prevent wrapping */
    justify-content: space-between; /* Adjust spacing between elements */
    width: 100%;
  }

  .response-container .other-textarea {
    width: 100%;
    margin-left: 0%; /* Full width for mobile */
  }    
  
}




.slider-label {
  white-space: nowrap;
  text-align: center;
  font-size: 0.75rem; 
}

//horizontaltextslider and gridbar labels overlap media query
@media (max-width: 768px) {
  .slider-label {
    white-space: normal; 
    word-wrap: break-word; 
    text-align: center; 
    font-size: 0.65rem; 
    max-width: 40px; 
    overflow-wrap: break-word; 
  }
}