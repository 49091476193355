.drag-container {
  width: auto;
  margin: 0 auto;
  flex-wrap: wrap;
  height: auto;
  min-height: 100px;
}

.drag-item {
  padding: 10px;
  margin: 5px;
  background-color:  #e2e2e2;
  border: 1px solid #ccc;
  cursor: move;
  transition: background-color 0.2s, opacity 0.2s;
  border-radius: 5px;
}

.drag-item:hover {
  background-color: var(--primary-color) !important;
  color: #fff;
}

.drop-box {
  width: 100%;
  min-height: 100px;
  margin-top: 20px;
  padding: 10px;
  border: 2px dashed #ccc;
  display: flex;
  flex-wrap: wrap;
}

.dropped-item {
  position: relative;
  padding: 10px;
  margin: 5px;
  background-color: var(--primary-color) !important;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
  display: flex;
  align-items: center;
  /* Allow interactions with dropped items */
  pointer-events: auto;
}

.remove-button {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: white;
  color: black;
  border: black;
  border-radius: 50%;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}