.image-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.checkbox-label {
  position: absolute;
  top: 0.3rem;
  left: 0.5rem;
  z-index: 10; 
  border-radius: 50%;
  accent-color: green;
  z-index: 10; 
}

.zoom-button {
  position: absolute; 
  top: 0.3rem;
  right: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
  border-radius: 50%;
  z-index: 10; 
  color: var(--primary-color) !important;
  background: transparent;
  
}

.zoom-button:hover {
  background-color: transparent;
   color: var(--primary-color) !important;
  transform: translateY(-2px);
}


.image-card {
  position: relative; 
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  border: 2px solid transparent; 
}

.selected {
  border-color: green;
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* .image-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
} */


input[type="checkbox"] {
  transform: scale(1.5);
  
}


/* .image-card-img {
  display: block;
  max-width: 100%;
  height: auto;
} */



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  padding: 20px;
  background: white;
  border-radius: 8px;
  max-width: 600px;
  max-height: 600px;
  width: 80%;
  height: auto;
}

.modal-image {
  height: 50vh;
  border-radius: 8px;
}

.close-button {
  position: absolute; 
  top: 0.3rem;
  right: 0.5rem;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10; 
  color: var(--primary-color) !important;
  font-size: 30px;
  padding: 2%;
  background:transparent;
}

.close-button:hover {
  background-color: transparent;
  color: var(--primary-color) !important; 
  transform: translateY(-2px);
}

